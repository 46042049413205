import React from "react";

function TelegramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      fill="none"
      viewBox="0 0 32 33"
    >
      <g clipPath="url(#clip0_323_2563)">
        <path
          fill="url(#paint0_linear_323_2563)"
          d="M21.232 23.747l2.625-12.375c.107-.524.045-.9-.187-1.125-.233-.226-.539-.268-.92-.125L7.321 16.068c-.345.131-.58.28-.705.447-.125.166-.14.324-.045.473.096.149.286.265.572.348l3.946 1.232L20.25 12.8c.25-.166.44-.202.571-.107.084.06.06.149-.071.268l-7.41 6.696-.286 4.072c.273 0 .541-.131.803-.393l1.929-1.857 4 2.946c.762.429 1.244.203 1.446-.678zM32 16.407a15.64 15.64 0 01-1.268 6.215c-.845 1.976-1.982 3.678-3.41 5.107-1.43 1.428-3.131 2.565-5.108 3.41A15.64 15.64 0 0116 32.408a15.64 15.64 0 01-6.214-1.267c-1.976-.846-3.679-1.983-5.107-3.411-1.429-1.429-2.566-3.131-3.411-5.107A15.641 15.641 0 010 16.407c0-2.166.423-4.238 1.268-6.214s1.982-3.678 3.41-5.107c1.43-1.429 3.132-2.565 5.108-3.41A15.64 15.64 0 0116 .406c2.167 0 4.238.423 6.214 1.268 1.977.846 3.679 1.982 5.107 3.411 1.429 1.429 2.566 3.131 3.411 5.107A15.64 15.64 0 0132 16.407z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_323_2563"
          x1="1.442"
          x2="37.902"
          y1="2.488"
          y2="36.333"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.016" stopColor="#52B5B6"></stop>
          <stop offset="1" stopColor="#123678"></stop>
        </linearGradient>
        <clipPath id="clip0_323_2563">
          <path
            fill="#fff"
            d="M0 0H32V32H0z"
            transform="translate(0 .407)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default TelegramIcon;
