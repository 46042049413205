import React from "react";

export function GroupSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="48"
      fill="none"
      viewBox="0 0 49 48"
    >
      <path
        stroke="#52B5B6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M19.842 14.777c-6.583-4.491-12.72-1.063-17.863 4.217l11.383 6.857m20.126 2.572c4.491 6.583 1.063 12.72-4.217 17.863l-6.857-11.383"
      ></path>
      <path
        stroke="#52B5B6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M18.368 17.211l12.686 12.686m-17.692-4.08l9.086 9.086c7.269-4.354 15.532-8.572 19.303-12.48 8.16-8.16 3.428-19.337 3.428-19.337S34.002-1.646 25.842 6.514c-3.908 3.772-8.16 12.069-12.48 19.303z"
      ></path>
      <path
        stroke="#52B5B6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M36.265 13.714a1.714 1.714 0 100-3.428 1.714 1.714 0 000 3.428zM12.882 42.651c-1.988 1.92-10.903 3.635-10.903 3.635s1.715-8.915 3.635-10.903a5.147 5.147 0 018.904 3.596 5.143 5.143 0 01-1.636 3.672z"
      ></path>
    </svg>
  );
}

export function SecuritySvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="53"
      height="52"
      fill="none"
      viewBox="0 0 53 52"
    >
      <path fill="#fff" d="M0 0H52V52H0z" transform="translate(.388)"></path>
      <path
        fill="#52B5B6"
        d="M26.388.953l21.666 7.584V26c0 8.942-5.488 15.193-10.608 19.073a42.78 42.78 0 01-10.263 5.694l-.054.02-.015.004-.007.002c-.002 0-.004 0-.72-2.043l-.716 2.045-.009-.004-.015-.004-.054-.022c-.29-.107-.577-.219-.863-.336a42.791 42.791 0 01-9.403-5.358C10.214 41.195 4.721 34.944 4.721 26.002V8.537L26.388.953zm0 47.797l-.717 2.045.717.252.717-.252-.717-2.045zm0-2.323l.02-.008a38.465 38.465 0 008.421-4.8c4.633-3.507 8.892-8.636 8.892-15.619V11.613L26.388 5.547 9.055 11.613V26c0 6.983 4.26 12.107 8.892 15.622a38.46 38.46 0 008.44 4.805zm13.156-28.35l-15.32 15.32-9.194-9.191 3.066-3.066 6.125 6.13 12.257-12.257 3.066 3.063z"
      ></path>
    </svg>
  );
}

export function AccountSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="50"
      fill="none"
      viewBox="0 0 51 50"
    >
      <path
        fill="#52B5B6"
        d="M25.39 29.167v4.166a12.5 12.5 0 00-12.5 12.5H8.722a16.667 16.667 0 0116.666-16.666zm0-2.084c-6.907 0-12.5-5.593-12.5-12.5 0-6.906 5.593-12.5 12.5-12.5 6.905 0 12.5 5.594 12.5 12.5 0 6.907-5.595 12.5-12.5 12.5zm0-4.166a8.331 8.331 0 008.332-8.334A8.331 8.331 0 0025.39 6.25a8.331 8.331 0 00-8.333 8.333 8.331 8.331 0 008.333 8.334zm5.405 16.273a7.3 7.3 0 010-3.38l-2.066-1.193 2.083-3.609 2.067 1.194a7.287 7.287 0 012.927-1.691v-2.386h4.166v2.386a7.274 7.274 0 012.928 1.691l2.066-1.194 2.084 3.609-2.065 1.194a7.316 7.316 0 010 3.379l2.065 1.193-2.084 3.609-2.066-1.194a7.28 7.28 0 01-2.928 1.692v2.385h-4.166V44.49a7.28 7.28 0 01-2.927-1.692l-2.067 1.194-2.083-3.609 2.067-1.193zm7.094 1.435a3.125 3.125 0 100-6.25 3.125 3.125 0 000 6.25z"
      ></path>
    </svg>
  );
}
export function BankSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="55"
      fill="none"
      viewBox="0 0 54 55"
    >
      <path
        fill="#52B5B6"
        d="M29.25 14.563a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm.072-8.832a3.937 3.937 0 00-4.645 0L7.98 17.915C5.78 19.519 6.916 23 9.636 23h.489v13.05a6.188 6.188 0 00-3.375 5.513v3.374c0 .93.756 1.688 1.687 1.688h37.125a1.688 1.688 0 001.688-1.688v-3.374a6.188 6.188 0 00-3.376-5.513V23h.489c2.722 0 3.854-3.48 1.656-5.085L29.322 5.731zm-2.655 2.725a.563.563 0 01.663 0l15.307 11.169H11.362L26.666 8.456zM40.498 23v12.375H36V23h4.5zm-7.875 0v12.375h-3.937V23h3.937zm-7.312 0v12.375h-3.938V23h3.938zM12.937 38.75h28.125a2.814 2.814 0 012.813 2.813v1.687h-33.75v-1.688a2.814 2.814 0 012.812-2.812zm.563-3.375V23H18v12.375h-4.5z"
      ></path>
    </svg>
  );
}

export function SuspiciousSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="43"
      fill="none"
      viewBox="0 0 43 43"
    >
      <g clipPath="url(#clip0_5_437)">
        <path
          fill="#52B5B6"
          d="M21.947 32h-2.625v-2.625h2.625V32zm0-23.625h-2.625V26.75h2.625V8.375zm-1.312 34.063a92.073 92.073 0 01-4.84-3.055 49.7 49.7 0 01-4.594-3.507 40.273 40.273 0 01-4.06-4.02 28.099 28.099 0 01-3.261-4.573 22.464 22.464 0 01-2.153-5.188c-.506-1.832-.766-3.78-.78-5.845V5.75a43.06 43.06 0 003.302-.123 23.127 23.127 0 003.117-.451 18.428 18.428 0 002.994-.923c.985-.397 1.97-.91 2.954-1.538 1.162-.752 2.324-1.306 3.486-1.661C17.962.698 19.24.514 20.635.5c.697 0 1.36.041 1.989.123.629.082 1.237.219 1.825.41.588.192 1.176.424 1.764.697.588.274 1.169.602 1.743.985a17.951 17.951 0 002.953 1.538c.985.396 1.983.704 2.994.923 1.012.219 2.051.369 3.117.45 1.067.083 2.167.124 3.302.124v10.5c0 2.064-.26 4.013-.779 5.845a23.733 23.733 0 01-2.153 5.188 27.176 27.176 0 01-3.24 4.573 38.7 38.7 0 01-4.061 4 55.02 55.02 0 01-4.594 3.506 80.269 80.269 0 01-4.86 3.076zM3.572 8.334v7.916c0 1.736.226 3.404.677 5.004.451 1.6 1.08 3.117 1.887 4.553a27.396 27.396 0 002.83 4.101 34.184 34.184 0 003.527 3.65 48.495 48.495 0 003.958 3.18 43.006 43.006 0 004.184 2.665 55.998 55.998 0 004.163-2.666 43.81 43.81 0 003.978-3.158 34.185 34.185 0 003.528-3.65 27.396 27.396 0 002.83-4.102A19.966 19.966 0 0037 21.254c.438-1.613.67-3.281.697-5.004V8.334a25.487 25.487 0 01-5.845-.984c-1.859-.547-3.657-1.374-5.393-2.482-.93-.601-1.853-1.039-2.769-1.312-.916-.274-1.934-.417-3.055-.431-1.108 0-2.12.137-3.035.41-.916.274-1.846.718-2.79 1.333A20.721 20.721 0 019.438 7.35c-1.859.546-3.814.875-5.865.984z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_5_437">
          <path
            fill="#fff"
            d="M0 0H42V42H0z"
            transform="translate(.947 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export function KycSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="57"
      height="57"
      fill="none"
      viewBox="0 0 57 57"
    >
      <rect
        width="52"
        height="52"
        x="2.389"
        y="2.5"
        stroke="#52B5B6"
        strokeWidth="4"
        rx="26"
      ></rect>
      <path
        fill="#52B5B6"
        d="M31.39 31.878v3.135a9 9 0 00-12 8.487h-3a12 12 0 0115-11.622zm-3-1.878c-4.973 0-9-4.027-9-9s4.027-9 9-9c4.972 0 9 4.027 9 9s-4.028 9-9 9zm0-3c3.314 0 6-2.685 6-6s-2.686-6-6-6c-3.316 0-6 2.685-6 6s2.684 6 6 6zm8.689 13.371l5.302-5.303 2.123 2.122-7.425 7.425-5.304-5.305 2.122-2.12 3.182 3.181z"
      ></path>
    </svg>
  );
}

export function TaxSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="54"
      fill="none"
      viewBox="0 0 55 54"
    >
      <path
        stroke="#52B5B6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M20.639 31.5l13.5-13.5m-12.375 1.125h.023m11.227 11.25h.022M43.14 47.25v-36a4.5 4.5 0 00-4.5-4.5h-22.5a4.5 4.5 0 00-4.5 4.5v36l7.875-4.5 7.875 4.5 7.875-4.5 7.875 4.5zm-20.25-28.125a1.125 1.125 0 11-2.25 0 1.125 1.125 0 012.25 0zm11.25 11.25a1.125 1.125 0 11-2.25 0 1.125 1.125 0 012.25 0z"
      ></path>
    </svg>
  );
}
export function TradeSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="54"
      fill="none"
      viewBox="0 0 55 54"
    >
      <path
        stroke="#52B5B6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M20.448 11.25v9M22.698 20.25h-4.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h4.5a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25zM20.448 33.75v4.5m18-31.5v4.5M40.697 11.25h-4.5a2.25 2.25 0 00-2.25 2.25V27a2.25 2.25 0 002.25 2.25h4.5a2.25 2.25 0 002.25-2.25V13.5a2.25 2.25 0 00-2.25-2.25z"
      ></path>
      <path
        stroke="#52B5B6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M38.447 29.25V36M6.947 6.75v40.5h40.5"
      ></path>
    </svg>
  );
}

export function LeftArrowSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="80"
      fill="none"
      viewBox="0 0 81 80"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        d="M47.055 23.333L30.39 40l16.666 16.667"
      ></path>
    </svg>
  );
}
