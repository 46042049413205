import React from "react";

function InstagramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      fill="none"
      viewBox="0 0 32 33"
    >
      <circle
        cx="16"
        cy="16.741"
        r="16"
        fill="url(#paint0_linear_323_2566)"
      ></circle>
      <path
        fill="#fff"
        d="M21.696 9.764a1.28 1.28 0 100 2.56 1.28 1.28 0 000-2.56zm4.907 2.582a8.096 8.096 0 00-.49-2.593 5.27 5.27 0 00-1.238-1.888 5.013 5.013 0 00-1.888-1.226 7.786 7.786 0 00-2.592-.502c-1.13-.064-1.494-.064-4.395-.064s-3.264 0-4.395.064a7.787 7.787 0 00-2.591.502 5.099 5.099 0 00-1.888 1.226 5.013 5.013 0 00-1.227 1.888 7.786 7.786 0 00-.502 2.592c-.064 1.131-.064 1.494-.064 4.395 0 2.902 0 3.264.064 4.395.019.886.188 1.763.502 2.592.26.714.68 1.36 1.226 1.888.53.544 1.176.963 1.889 1.227.829.313 1.705.483 2.591.5 1.131.065 1.494.065 4.395.065s3.264 0 4.395-.064a7.785 7.785 0 002.592-.502c.714-.26 1.36-.68 1.888-1.226a5.174 5.174 0 001.237-1.888c.304-.832.47-1.707.49-2.592 0-1.13.065-1.494.065-4.395s0-3.264-.064-4.394zm-1.92 8.66a5.984 5.984 0 01-.363 1.985c-.17.464-.444.884-.8 1.227a3.404 3.404 0 01-1.226.8 5.984 5.984 0 01-1.985.362c-1.066.053-1.46.064-4.266.064-2.805 0-3.2 0-4.267-.064a6.114 6.114 0 01-2.07-.32 3.488 3.488 0 01-1.172-.8 3.2 3.2 0 01-.79-1.227 5.91 5.91 0 01-.426-2.026c0-1.067-.064-1.462-.064-4.267 0-2.805 0-3.2.064-4.266a5.91 5.91 0 01.373-2.027 3.2 3.2 0 01.843-1.174 3.35 3.35 0 011.173-.853 6.112 6.112 0 012.026-.363c1.067 0 1.462-.064 4.267-.064 2.805 0 3.2 0 4.267.064.677.008 1.348.13 1.984.363.485.18.92.473 1.27.853.349.328.622.729.8 1.174.236.65.359 1.335.362 2.027.053 1.066.064 1.46.064 4.266s-.01 3.2-.064 4.267zM16 11.269a5.472 5.472 0 105.483 5.472A5.463 5.463 0 0016 11.268zm0 9.024a3.552 3.552 0 110-7.104 3.552 3.552 0 010 7.104z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_323_2566"
          x1="1.442"
          x2="37.902"
          y1="2.821"
          y2="36.666"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.016" stopColor="#52B5B6"></stop>
          <stop offset="1" stopColor="#123678"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default InstagramIcon;
